/* engraving > pc and mobile */

.cart-item {
  .engraving__cart-control {
    left: 22%;
    width: 78%;
    display: inline-block;
    margin-bottom: 10px;
    @include breakpoint($landscape-up) {
      left: 15%;
      width: 85%;
    }
    .engraving-show-toggle {
      margin-right: 5%;
      line-height: 20px;
    }
  }
}

.engraving-create {
  display: none;
  background-color: $color-white-smoke;
  h3 {
    font-size: 20px;
    text-transform: uppercase;
    padding-bottom: 10px;
  }
}

.engraving {
  padding: 2em 1em 1em 2em;
  background-color: $color-white-smoke;
  .icon {
    &.icon--caret--up {
      @extend .icon--minus;
    }
    &.icon--caret--down {
      @extend %hide;
      @extend .icon--plus;
    }
  }
  &__form {
    float: left;
    width: 65%;
    &-element {
      margin: 0 0 1em 0;
      display: block;
      .engraving__label-intro {
        float: none;
      }
      .engraving__message-count-container {
        font-size: 13px;
        margin-top: 5px;
      }
      &-footer {
        padding: 1em 1em 0 0;
        input {
          margin-right: 10px;
        }
        .button {
          display: none;
        }
      }
    }
    &-font {
      width: 45%;
    }
  }
  &__headline-black {
    font-family: $font--lamer-headline;
  }
  &__image {
    float: right;
    width: 35%;
    &-title {
      margin-bottom: 10px;
    }
    &-notification {
      font-size: 12px;
    }
  }
  &__live-preview {
    width: 270px;
    height: 270px;
    position: relative;
    &-text {
      position: absolute;
      top: 127.5px;
      text-align: center;
      width: 100%;
      &.engraving__view-multiline {
        top: 121px;
      }
      &-line {
        margin-bottom: 5px;
      }
    }
    &-enlarge {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      &.button--disabled {
        background: none;
      }
      img {
        width: 25px;
      }
    }
  }
  &__label {
    border: 1px solid $color-gray-light-apt;
    background-color: $color-white;
    border-radius: 4px;
    padding: 0.75em 1.5em;
    display: inline-block;
    cursor: pointer;
    text-transform: capitalize;
    &-wrapper {
      margin: 1em 1em 0 0;
    }
    &-intro {
      float: left;
      margin-right: 10px;
    }
  }
  &__type {
    text-transform: capitalize;
    font-size: 15px;
  }
  &__selected {
    border-color: $color-black;
    color: $color-black;
  }
  &__cart-error-message {
    color: $color-error;
    display: none;
  }
  &__edit {
    &:after {
      content: ' ';
      display: table;
      clear: both;
    }
  }
  a.engraving-toggle-edit {
    margin-right: 10px;
    color: $color-black;
    border: 1px solid $color-black;
  }
  &__view-title {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &__view-message {
    float: left;
    width: 50%;
    &-title {
      margin: 0 10px 10px 0;
      float: left;
    }
    &-preview {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 5px;
      &.engraving__headline-black {
        top: 3px;
        position: relative;
      }
    }
    &-bottom {
      margin-top: 15px;
      float: left;
      width: 50%;
      .type {
        font-size: 20px;
        margin-bottom: 18px;
        text-transform: capitalize;
      }
      p {
        margin: 20px 0 40px;
      }
      & ~ p {
        float: left;
        margin: 15px 0;
        line-height: 40px;
      }
    }
  }
  &__message-text {
    margin-top: 15px;
    span {
      font-weight: bold;
    }
  }
  &-copy {
    margin-bottom: 7px;
    display: block;
  }
  &__placement {
    margin-bottom: 7px;
  }
  &__overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 999;
    .popup {
      width: 440px;
      background: $color-white;
      margin: 100px auto;
      &__zoom-container {
        padding: 20px;
        h3 {
          font-size: 20px;
          text-transform: uppercase;
          line-height: 30px;
          span {
            float: right;
            width: 20px;
            height: 20px;
            cursor: pointer;
            line-height: 25px;
            font-size: 40px;
          }
          &:after {
            content: ' ';
            display: table;
            clear: both;
          }
        }
        .button {
          display: none;
        }
      }
      &__zoom-content {
        width: 400px;
        height: 400px;
        position: relative;
        .engraving__live-preview-text {
          font-size: 22.22px;
          line-height: 22.22px;
          top: 188.89px;
          &.engraving__view-multiline {
            top: 174.58px;
          }
          &-line {
            margin-bottom: 7.4px;
          }
        }
      }
    }
  }
}

.engraved {
  &__image {
    position: relative;
    height: 270px;
    .engrave__placement {
      &.lid {
        position: absolute;
        top: 125px;
        width: 100%;
        text-align: center;
        &__50ml {
          font-size: 14px;
        }
      }
    }
  }
  &__text {
    background-color: $color-gray-darker;
    font-size: 15px;
    line-height: 15px;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    color: $color-gray-darker;
    &.white-lid {
      background: none;
      color: $color-autumn-gold;
      -o-text-overflow: clip;
      text-overflow: clip;
      font-kerning: normal;
      -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms, all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms, all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms, all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms, all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      -webkit-transform: rotateX(3.437746770784939deg) rotateY(4.010704565915763deg);
      transform: rotateX(3.437746770784939deg) rotateY(4.010704565915763deg);
      text-shadow: none;
    }
  }
}
/* engraving > pc and mobile */

/* engraving > mobile */
@include breakpoint($landscape-down) {
  .cart-item {
    .engraving__cart-control {
      width: 100%;
      margin-top: 10px;
      span {
        display: block;
        text-align: center;
        margin-bottom: 10px;
        .engraving-show-toggle {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  .engraving {
    padding-left: 1em;
    &__form {
      width: 100%;
      margin-bottom: 15px;
      &-element-footer {
        .button {
          margin-right: 10px;
          display: inline-block;
        }
        .engraving-save {
          display: none;
        }
      }
    }
    &__image {
      display: none;
      width: 100%;
      margin-bottom: 15px;
      &-title.engraved {
        background-color: $color-white;
        padding: 1em 0.5em;
        text-transform: uppercase;
      }
    }
    &__view-message {
      width: 100%;
      &-bottom {
        margin: 0 0 10px 0;
        text-align: center;
        float: none;
        width: 100%;
        a.engraving-toggle-edit {
          width: 100%;
          margin: 10px 0;
        }
        & ~ p {
          margin-top: 0;
          line-height: 20px;
          text-align: center;
          float: none;
        }
      }
      &-title {
        float: none;
      }
    }
    &__label-intro {
      float: none;
    }
    &__overlay {
      overflow: scroll;
      .popup {
        width: 340px;
        margin: 80px auto;
        &__zoom-container {
          h3 span {
            display: none;
          }
        }
        &__zoom-content {
          width: 300px;
          height: 300px;
          margin-bottom: 15px;
          .engraving__live-preview-text {
            font-size: 16.67px;
            line-height: 16.67px;
            top: 141.67px;
            &.engraving__view-multiline {
              top: 130.55px;
            }
            &-line {
              margin-bottom: 5.5px;
            }
          }
        }
        .button {
          width: 100%;
          margin-bottom: 10px;
          display: block;
        }
      }
    }
  }
}
/* engraving > mobile */

/* engraving > iPad landscape only */
@include breakpoint($medium-landscape-only) {
  .engraving {
    &__form {
      width: 50%;
    }
    &__image {
      width: 50%;
    }
  }
}
/* engraving > iPad landscape only */

/* About Engraving popup */
.checkout-content-popup {
  .basic-grid__item {
    .content-block-tout {
      &__inner {
        img {
          width: 100%;
        }
      }
      &__media {
        max-width: 60%;
        margin: 30px auto 10px;
        height: 280px;
        @include breakpoint($medium-up) {
          max-width: 100%;
          margin: auto;
        }
      }
      @include breakpoint($medium-up) {
        max-width: 30%;
        float: left;
        margin: 0 1.5%;
        overflow: hidden;
      }
    }
  }
}
/* About Engraving > PC */

/* Engraving Content Page */
.section-engraving-content {
  .content-block-tout {
    &__media {
      max-width: 60%;
      margin: 30px auto 10px;
      height: 280px;
      @include breakpoint($medium-up) {
        max-width: 100%;
        margin: auto;
      }
    }
  }
}
/* Engraving Content Page */

/* cart confirm Engraving */
.cart-confirm {
  &__engrave-container {
    .engraving {
      display: none;
    }
  }
}
