.appointments-page {
  &.account-section {
    #appointment-book-container {
      @include breakpoint($landscape-up) {
        width: 80%;
        float: left;
        display: block;
        position: relative;
      }
      .appt-book {
        &-page-header {
          h2 {
            font-size: 27px;
            text-align: left;
          }
          h4 {
            text-align: left;
          }
        }
        &-sections-container {
          margin: 0;
        }
      }
    }
  }
}
