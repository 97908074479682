.paypal {
  .terms_condition label.terms_input {
    &:before {
      content: '\e00b';
    }
  }
  input[type='checkbox']:checked ~ .label:before,
  input[type='checkbox']:checked ~ label:before {
    content: '\e00a';
  }
}

.checkout-panel {
  &--return-user {
    .return-user-content {
      .form-item--forgot-password {
        text-align: center;
      }
      .show-password {
        text-align: right;
        padding: 10px;
      }
    }
  }
}
