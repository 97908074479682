// UK Common styles

@import 'sign_in';
@import 'account_address_book';
@import 'account-settings';
@import 'account_appointment';
@import 'account_orders';

.account-page {
  .product-grid__item {
    .product-brief {
      .product-info__unit-price {
        color: $color-light-gray;
        font-style: italic;
      }
    }
  }
  .giftcard-checkbalance-page__content {
    padding: 10px;
  }
}

.account-section {
  &.past-purchases-page,
  &.favorites-page {
    .product-item {
      background-color: $color-mpp-light-gray;
      border: none;
      .product-item__details {
        padding: 0 14%;
        .product-info__unit-price {
          color: $color-medium-gray;
          font-style: italic;
        }
        .product-rating {
          .reviews-processed {
            > img {
              display: none;
            }
          }
        }
      }
      .favorite-actions {
        margin-top: 17px;
        @include breakpoint($landscape-up) {
          margin-top: 7px;
        }
        .favorite-actions__link {
          font-size: 10px;
          letter-spacing: 0.075em;
          margin-right: 0;
          text-decoration: none;
        }
      }
    }
  }
  &.add-payment-page {
    .payment-address {
      .address-container {
        margin-bottom: 1em;
      }
    }
    .payment-form,
    .payment-form__item {
      select {
        height: calc(3em - -2px);
        font-size: 1em;
      }
    }
  }
}

.password-request-page {
  .password-request {
    .request-form {
      .back-sign-in {
        border: none;
      }
    }
  }
}

.password-sent-page {
  .sent-info {
    &__sign-in-back {
      border: none;
    }
  }
}

.password-reset-page {
  .password-reset {
    &__fieldset {
      .back-sign-in {
        border: none;
      }
    }
  }
}
