.profile {
  &-page {
    .optional-info {
      &__item {
        &.skin_type,
        &.skin_concern {
          label {
            display: block;
            margin-bottom: 10px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-section-content {
    padding: 5px 25px 0;
    .service-content-container {
      padding: 0 25px;
      .service-details {
        .lesson_description {
          padding: 0 20px;
        }
      }
    }
    input[type='checkbox'],
    input[type='checkbox'] ~ label::before,
    input[type='checkbox'] ~ .label::before,
    input[type='radio'],
    input[type='radio'] ~ label::before,
    input[type='radio'] ~ .label::before {
      float: left;
      margin-right: 7px;
      margin-left: 0px;
      margin-top: 4px;
    }
  }
}
