.ff-results-share {
  &__cta {
    &-social-media {
      top: -171px;
      @include breakpoint($landscape-up) {
        top: 0;
      }
    }
    &-spacer {
      min-height: 0;
    }
  }
}
