// sass-lint:disable placeholder-in-extend
// Discuss this with Chris Phillips or Dom Hastings if required.
// This feature is currently needed to extend the NA (base) styles whilst keeping
// the UK markup consistent.

/* Mixins */
// @extend this within the panel selector to have the panel collapsed by default
%collapsed_accordion {
  .checkout-panel__heading {
    .icon--caret--up {
      @extend %hide;
    }
    .icon--caret--down {
      @extend %show;
    }
  }
  .checkout-panel__content {
    @extend %hide;
  }
}

body.checkout {
  padding: 0;
  max-width: 100%;
  @extend .checkout-viewcart;
  .site-content {
    padding: 0;
  }
}
/* fix issues on viewcart due to mobile ux and the signin panel state */
#viewcart.active-panel-signin {
  header.site-header {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .site-header.sticky,
  .site-header__search,
  .site-header__menu.active {
    display: block;
  }
  .site-header__utility-left,
  .site-header__utility-right {
    @extend %show;
    &.pc-hidden,
    &.pc_hidden {
      @extend %show;
      @extend %hide_on_desktop;
      .site-header__utility-item {
        @extend %show_on_desktop;
      }
      .site-header__utility-item-nav {
        @extend %show;
        @extend %hide_on_desktop;
      }
    }
    &.mobile_hidden,
    &.mobile-hidden {
      @extend %hide;
      @extend %show_on_desktop;
    }
  }
  .site-header__utility-logo {
    min-height: 0;
    .site-header__utility-item__link-logo {
      line-height: inherit;
    }
  }
}
/* fix issues on viewcart due to mobile ux and the signin panel state */

/* UX version 2 */
.checkout_spc_v2 {
  .checkout .column {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: column;
  }
  &.checkout--index {
    @extend .checkout-index;
  }
  .checkout-panel--sticky-footer {
    .checkout-buttons-content__container {
      @include breakpoint($iphonex-landscape-only) {
        float: none;
      }
    }
  }
  &.sticky-footer-visible {
    .checkout-panel {
      &--sticky-footer {
        .adyen-viewcart-buttons-panel {
          .paypal-checkout {
            border: 0;
            float: none;
          }
          .continue-checkout {
            float: none;
          }
        }
      }
    }
  }
  .adyen_enabled {
    .samples-page {
      .checkout-panel--samples {
        .continue-shopping__container--outer {
          .continue-shopping__container--inner {
            display: block;
          }
        }
      }
    }
  }
}
/* UX version 2 */

.checkout__header {
  .active-panel-signin & {
    @extend %show;
  }
  .checkout__content.column.left & {
    @extend %hide;
  }
}

.checkout__content {
  &.column {
    &.left {
      @include breakpoint($iphonex-landscape-only) {
        width: 100%;
      }
    }
    &.checkout__sidebar {
      @include breakpoint($iphonex-landscape-only) {
        display: none;
      }
    }
  }
}

.checkout__sidebar {
  @include breakpoint($medium-up) {
    padding-bottom: 0;
  }
  .active-panel-signin & {
    @extend %show;
  }
  .tab-switcher {
    .checkout.viewcart & {
      @extend %hide;
      @extend %show_on_desktop-block;
    }
  }
  .afterpay {
    display: none;
  }
}

.checkout {
  &.viewcart {
    select::-ms-expand {
      display: none;
    }
  }
}

.checkout-panel {
  select::-ms-expand {
    display: none;
  }
}
/* Panels */
section.checkout-panel--sticky-footer {
  @extend %hide;
  .checkout_spc_v2.sticky-footer-visible & {
    @extend %show;
    @extend .sticky-checkout-button;
    .paypal-checkout {
      width: 49%;
      float: left;
      &.disabled {
        border: none;
        padding-top: 0;
        img {
          opacity: 0.2;
          border: 1px solid $color-light-gray;
          padding-top: 1px;
          height: 47px;
        }
        span.paypal-message {
          font-size: 10px;
          @extend %show-block;
        }
      }
    }
    .continue-checkout {
      width: 49%;
      float: right;
    }
    .samples-buttons-panel {
      a.no-samples {
        @extend %hide;
      }
    }
  }
  .continue-shopping,
  span.or,
  span.applepay-or,
  .applepay-integration-wrapper {
    @extend %hide;
  }
}

section.checkout-panel--promo-panel {
  padding: 0;
  border: none;
}

section.checkout-panel--shortcuts-panel {
  border-bottom: 0 none;
  text-align: center;
  .shortcuts-content__list--link {
    text-transform: uppercase;
  }
}

section.checkout-panel--sign-in-main {
  .checkout-panel__header {
    display: none;
  }
  .checkout.viewcart .column.left & {
    .checkout_spc_v2.checkout_spc_v2--show-sign-in-on-viewcart & {
      /* Example of feature enabling classnames */
      @extend %show;
      order: 1;
      -webkit-order: 1; /* Safari 6.1+ */
    }
    @extend %hide;
  }
}

section.checkout-panel--shipmethod {
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 1em;
  }
  .checkout.viewcart .column.left & {
    text-align: right;
    .checkout-panel__header {
      .checkout_spc_v2 & {
        @extend %hide;
      }
    }
    .ship-method-group-label {
      @include clearfix;
      .shipment-option:first-child {
        margin-right: 3%;
        .checkout_spc_v2 & {
          float: left;
        }
      }
      .shipment-option:last-child {
        margin-right: 0;
        .checkout_spc_v2 & {
          float: right;
        }
      }
    }
    .clear {
      display: block;
      padding-top: 5%;
      @include breakpoint($medium-up) {
        padding-top: 2%;
      }
    }
    .ship-method-group {
      width: 100%;
      select {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 60%;
        }
      }
    }
  }
  .checkout.index .column.left & {
    @extend %hide;
    .checkout_spc_v2 & {
      @extend %show;
    }
  }
  .checkout.index .column.right & {
    @extend %show-block;
    .checkout_spc_v2 & {
      @extend %hide;
    }
    .clear {
      display: block;
      padding-top: 5%;
      @include breakpoint($medium-up) {
        padding-top: 2%;
      }
    }
    .ship-method-group {
      width: 100%;
      select {
        width: 100%;
      }
    }
  }
  .ship-method-group-label {
    a.more-info {
      background: url('/media/export/images/click_collect/more-info-icon.png') no-repeat right
        center;
      background-size: contain;
      float: left;
      width: 15px;
      height: 15px;
      padding-top: 23px;
      margin-right: 5px;
      @include breakpoint($medium-up) {
        float: right;
        margin-right: 0;
        margin-left: 5px;
      }
      span {
        @extend %hide;
      }
      &.loading {
        background-image: url('/media/images/global/ajax-loading.gif');
      }
    }
  }
  .checkout_spc_v2 & {
    order: 5;
    -webkit-order: 5; /* Safari 6.1+ */
  }
}

section.checkout-panel--order-summary {
  padding: 0;
  border-top: 1px solid $color-lightest-grey;
  @include breakpoint($medium-up) {
    padding: 1em;
  }
  .checkout.viewcart .column.left & {
    text-align: right;
    .checkout_spc_v2 & {
      text-align: left;
      border-bottom: none;
    }
    .label {
      @include breakpoint($medium-up) {
        width: 85%;
      }
    }
    .afterpay {
      width: 100%;
      font-family: $font--neue-haas-unica-pro;
      font-size: 15px;
      font-weight: normal;
      .afterpay-learnmore {
        cursor: pointer;
        border: none;
        &.afterpay-error-popup {
          float: #{$rdirection};
          width: 21px;
          height: 24px;
          line-height: 1.3;
          margin-top: 21px;
          @include breakpoint($medium-up) {
            margin-top: 0;
          }
        }
        span {
          width: 17px;
          height: 17px;
          font-size: 14px;
          line-height: 1.1;
          vertical-align: middle;
          text-align: center;
          font-weight: normal;
          padding-#{$ldirection}: 1px;
        }
      }
      img {
        width: 118px;
      }
      .popup-logo {
        display: inline-block;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
    }
    .value {
      @include breakpoint($medium-up) {
        width: 15%;
      }
    }
    .order-summary-content__label--shipping,
    .order-summary-content__value--shipping,
    .order-summary-content__label--donation,
    .order-summary-content__value--donation {
      @extend %show;
    }
  }
  .checkout_spc_v2 & {
    order: 4;
    -webkit-order: 4; /* Safari 6.1+ */
  }
}

section.checkout-panel--guarantee {
  .checkout.viewcart .column.left & {
    @extend %hide;
    .checkout_spc_v2 & {
      @extend %show;
      order: 7;
      -webkit-order: 7; /* Safari 6.1+ */
    }
  }
  .checkout.index .column.right & {
    @extend %hide;
    .checkout_spc_v2 & {
      @extend %show;
      order: 3;
      -webkit-order: 3; /* Safari 6.1+ */
    }
  }
  .checkout--confirmation-page & {
    padding: 0;
    clear: both;
    @include breakpoint($medium-up) {
      padding: 1em;
    }
    .checkout-panel__heading {
      font-size: 20px;
      text-transform: uppercase;
    }
  }
}

section.checkout-panel--past-purchases {
  @extend %hide;
  @extend %show_on_desktop;
  .checkout_spc_v2 & {
    @extend %hide;
  }
}

section.checkout-panel--need-help {
  @extend .checkout-panel--offer-code;
  .checkout-panel__heading .icon {
    @extend .toggle-icon;
  }
}

section.checkout-panel--links {
  .checkout-panel__heading .icon {
    @extend .toggle-icon;
  }
  .phone_number,
  .email_us {
    @extend %hide;
    .checkout_spc_v2 & {
      @extend %show-block;
    }
  }
  .checkout_spc_v2 & {
    @extend %collapsed_accordion;
  }
}

section.checkout-panel--offer-code {
  .checkout-panel__heading .icon {
    @extend .toggle-icon;
  }
  .checkout_spc_v2 & {
    @extend %collapsed_accordion;
  }
  .checkout.viewcart .column.left & {
    @extend %hide;
    .checkout_spc_v2 & {
      order: 6;
      -webkit-order: 6; /* Safari 6.1+ */
    }
  }
  .checkout.viewcart .column.right & {
    @extend %show-block;
  }
}

section.checkout-panel--viewcart {
  @include breakpoint($medium-up) {
    border-bottom-width: 0;
  }
  @extend .checkout-panel--offer-code;
  .checkout-panel__heading .icon {
    @extend .toggle-icon;
  }
  .messages {
    display: table;
    #error_offer_criteria_not_met {
      display: table-footer-group;
    }
  }
  .checkout.viewcart .column.left & {
    @extend %show-block;
    .cart-items__header {
      .cart-items__header--products {
        width: 61%;
      }
    }
    .cart-items__item {
      border-top: 1px solid $color-lightest-grey;
      padding-bottom: 0;
      &:first-child {
        border-top: 1px solid $color-lightest-grey;
      }
      .cart-items__item--thumb {
        margin-right: 0;
      }
      .cart-items__item--desc {
        width: 70%;
        @include breakpoint($medium-up) {
          width: 45%;
          @include breakpoint($medium-portrait-only) {
            width: 37%;
          }
          float: left;
          margin-right: 0;
        }
        .cart--item__remove-form {
          font-size: 12px;
          @include breakpoint($medium-up) {
            font-size: 14px;
            text-transform: uppercase;
          }
          .checkout_spc_v2 & {
            text-transform: uppercase;
          }
        }
        @include breakpoint($iphonex-landscape-only) {
          width: 78%;
          height: 160px;
        }
      }
      .cart-items__item--price {
        width: 24%;
        clear: both;
        text-align: center;
        @include breakpoint($medium-up) {
          clear: none;
          width: 14%;
          text-align: left;
        }
        .checkout_spc_v2 & {
          text-align: center;
        }
      }
      .cart-items__item--qty {
        width: 35%;
        margin-top: 0;
        text-align: right;
        @include breakpoint($medium-up) {
          width: 13%;
          text-align: left;
        }
        .checkout_spc_v2 & {
          margin-left: 30%;
          text-align: left;
          width: 27%;
        }
      }
      .cart-items__item--total {
        width: 30%;
        @include breakpoint($medium-up) {
          width: 11%;
        }
        .checkout_spc_v2 & {
          width: 19%;
        }
      }
    }
    .checkout_spc_v2 & {
      order: 2;
      -webkit-order: 2; /* Safari 6.1+ */
    }
  }
  .checkout.index .column.right & {
    .checkout-panel__header {
      .item-count__language {
        text-transform: capitalize;
      }
    }
    .cart-items__item {
      .cart-items__item--desc {
        float: left;
        width: 49%;
        @include breakpoint($medium-up) {
          width: 59%;
        }
        @include breakpoint($landscape-up) {
          margin-bottom: 1%;
        }
      }
      .cart-items__item--qty {
        float: right;
        width: 76%;
        margin: 0;
        @include breakpoint($medium-up) {
          width: 84%;
        }
      }
      .cart-items__item--total {
        position: absolute;
        right: 0;
        top: 15px;
        float: right;
        text-align: right;
        width: 25%;
        @include breakpoint($landscape-up) {
          top: 28px;
        }
      }
    }
  }
}

section.checkout-panel--donations {
  .checkout.viewcart .column.left & {
    background-color: $color-white-smoke;
    .donation_logo {
      float: left;
    }
    .donation_logo_mobile {
      float: left;
      width: 100%;
      margin-bottom: 10px;
    }
    .donation_message {
      width: 70%;
      margin: 0 0 20px 18%;
    }
    .donation_amounts_panel {
      @include breakpoint($medium-up) {
        margin-left: 18%;
      }
    }
    input[type='radio'] ~ .label:before,
    input[type='radio'] ~ label:before {
      background-color: $white;
      border-radius: 50%;
      float: left;
      margin: 4px 4px 0 7px;
    }
    #select-amount,
    #select-amount-mobile {
      font-weight: bold;
    }
    .checkout_spc_v2 & {
      order: 3;
      -webkit-order: 3; /* Safari 6.1+ */
    }
  }
}

section.checkout-panel--viewcart-buttons {
  @extend .checkout-panel--order-summary;
  padding: 1em 0;
  @include breakpoint($medium-up) {
    padding: 1em;
    border-width: 0;
  }
  .total-container {
    .total-container__content {
      .total {
        text-transform: uppercase;
      }
    }
  }
  .bottom-viewcart-buttons {
    padding: 0;
    @include breakpoint($medium-up) {
      padding: 1em 0em;
    }
    .continue-checkout {
      width: 100%;
      margin: 5% 0 0;
      padding: 15px;
      @include breakpoint($medium-up) {
        width: 25%;
        margin: 0;
      }
    }
    .paypal-checkout {
      width: 100%;
      margin: 5% 0 0;
      @include breakpoint($medium-up) {
        width: 25%;
        padding: 0.15em 10px;
        margin: 0 5% 0 0;
      }
      &.disabled {
        border: none;
        padding-top: 0;
        @include breakpoint($medium-up) {
          width: 33%;
        }
        img {
          opacity: 0.2;
          border: 1px solid $color-light-gray;
          padding-top: 1px;
          height: 45px;
          padding-left: 25%;
          padding-right: 25%;
          @include breakpoint($medium-up) {
            height: 41px;
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
          }
        }
        span.paypal-message {
          font-size: 10px;
          @extend %show-block;
          @extend %show_on_desktop;
        }
      }
    }
    span.or {
      @extend %show;
      font-size: 15px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      margin: 5% 0 0;
      @include breakpoint($medium-up) {
        margin: 0 5% 0 0;
      }
    }
    span.applepay-or {
      @extend %hide;
    }
    .checkout-buttons-content {
      text-align: center;
      .checkout_spc_v2 & {
        display: block;
      }
      .continue-shopping {
        display: inline-block;
        margin: 5% auto auto;
        @include breakpoint($medium-up) {
          width: auto;
          margin: 0;
        }
        .checkout_spc_v2 & {
          order: 2;
          -webkit-order: 2; /* Safari 6.1+ */
        }
      }
      .checkout-buttons-content__container {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 70%;
        }
        .checkout_spc_v2 & {
          display: block;
        }
        .paypal-checkout {
          .checkout_spc_v2 & {
            order: 4;
            -webkit-order: 4; /* Safari 6.1+ */
          }
        }
        .continue-checkout {
          .checkout_spc_v2 & {
            order: 1;
            -webkit-order: 1; /* Safari 6.1+ */
          }
        }
        .or {
          .checkout_spc_v2 & {
            order: 5;
            -webkit-order: 5; /* Safari 6.1+ */
          }
        }
      }
    }
  }
  .checkout_spc_v2 & {
    order: 8;
    -webkit-order: 8; /* Safari 6.1+ */
  }
  .go-shopping--btn {
    float: left;
  }
}

section.checkout-panel--shipping {
  fieldset {
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    .form-item {
      width: 100%;
      @include breakpoint($medium-up) {
        float: left;
        padding-right: 1.3333333em;
        width: 50%;
        .form-item--full {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
      .title-container {
        label {
          @extend %hide;
        }
      }
      input,
      select,
      textarea {
        width: 100%;
      }
      .button {
        margin-left: 1px;
        width: 100%;
        cursor: pointer;
      }
    }
    .form-item.submit {
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
  }
  fieldset.giftwrap {
    .checkout-panel__heading,
    div.giftwrap {
      margin-top: 1em;
    }
    ul.gift-options-list {
      overflow: hidden;
      clear: both;
      .gift-option {
        width: 48%;
        margin: 0 4% 0 0;
        float: left;
        &:nth-child(2n) {
          margin: 0;
        }
        @include breakpoint($medium-up) {
          width: auto;
          width: 22%;
          margin: 0 4% 0 0;
          &:nth-child(2n) {
            margin: 0 4% 0 0;
          }
          &:nth-child(4n) {
            margin: 0;
          }
        }
        label:before {
          content: '';
        }
        label,
        label div.button {
          display: block;
        }
        .radio-option-button-text .icon {
          vertical-align: bottom;
        }
      }
    }
  }
  .shipping-gift-options-wrap {
    width: 75%;
  }
  .continue-button-wrapper {
    text-align: right;
  }
}
//!important is used below to override the inline styles from 3rd party content
.afterpay-paragraph {
  .afterpay-text1 {
    margin-#{$rdirection}: 4px;
  }
  .afterpay-link {
    height: 15px;
    width: 15px;
    vertical-align: middle;
    padding-#{$rdirection}: 0;
    margin-#{$ldirection}: 5px;
    text-decoration: none !important;
    color: #{$white} !important;
  }
}

#viewcart {
  #colorbox {
    min-height: 600px;
  }
}

section.checkout-panel--review {
  .checkout.index .column.left & {
    .review-edit-content {
      .payment-method-header,
      .checkout__panel--heading,
      .set-address h3 {
        margin: 3% 0;
        text-transform: uppercase;
      }
      .checkout__panel--heading {
        .change-address {
          font-size: 13px;
        }
      }
      .creditcard-option {
        border-top: 1px solid #{$color-light-gray};
      }
      input[type='radio'],
      input[type='radio'] {
        & ~ label {
          &:before {
            @include swap_direction(margin, 4px 6px 0 0);
            float: #{$ldirection};
          }
        }
      }
      .payment-options {
        @include swap_direction(padding, 20px 0 20px 10px);
        .paypal-image {
          width: 85px;
          margin-#{$ldirection}: 4px;
        }
        .afterpay-image {
          @include swap_direction(margin, 0 2px);
          width: 118px;
        }
      }
      .paypal-option {
        border-top: 1px solid #{$color-light-gray};
        border-bottom: 1px solid #{$color-light-gray};
      }
      .submit {
        text-align: #{$rdirection};
      }
      .afterpay-option {
        border-bottom: 1px solid #{$color-light-gray};
        padding-bottom: 10px;
        .afterpay-learnmore {
          border: none;
          line-height: 2.5;
          span {
            @include swap_direction(margin, 0 5px);
            background: #{$black};
            border-radius: 50%;
            height: 17px;
            width: 17px;
            line-height: 1.3;
            text-align: center;
            padding-#{$ldirection}: 1px;
            color: #{$white};
            display: inline-block;
            text-transform: lowercase;
            vertical-align: middle;
            font-size: 12px;
          }
        }
        label {
          width: 100%;
          @include breakpoint($landscape-up) {
            width: auto;
          }
        }
        .afterpay {
          @include swap_direction(margin, 0 5px 0 0);
        }
        .afterpay-disclaimer {
          width: 100%;
          display: block;
          @include breakpoint($landscape-up) {
            width: 71%;
            display: block;
            margin-#{$ldirection}: 26%;
          }
        }
      }
      .address {
        clear: both;
      }
      .payment-method__item {
        margin-bottom: 5%;
        @include breakpoint($medium-up) {
          margin-bottom: 2%;
          border-bottom: 1px solid #{$color-light-gray};
        }
        .checkout_spc_v2 & {
          margin-bottom: 5%;
        }
        .payment-option {
          @include swap_direction(padding, 20px 0);
          display: inline-block;
          vertical-align: middle;
          width: 49%;
          border-top: 1px solid #{$color-light-gray};
          @include breakpoint($medium-up) {
            width: 100%;
          }
          .related-media {
            &.pp {
              img {
                height: 1.7em;
              }
            }
          }
        }
      }
    }
    #checkout_payment {
      padding: 0;
      margin-bottom: 20px;
      &:after {
        @include clearfix;
      }
      .card-number {
        width: 100%;
        @include breakpoint($medium-up) {
          display: -webkit-flex; /* Safari */
          display: flex;
          flex-direction: column;
          float: left;
          padding-bottom: 5%;
          width: 49%;
        }
        .card_number_field {
          width: 100%;
        }
        .form-item {
          @include breakpoint($medium-up) {
            order: 2;
            -webkit-order: 2;
          }
        }
        .card-type-visual-indicator {
          text-align: left;
          @include breakpoint($medium-up) {
            order: 1;
            -webkit-order: 1;
          }
          .card-type {
            @extend %show;
            background-repeat: no-repeat;
            background-size: 43px 28px;
            border: 1px solid $white;
            height: 28px;
            width: 43px;
            opacity: 0.25;
            /* visa */
            &.card-type-001 {
              background-image: url('/media/images/payment/visa.png');
            }
            /* mastercard */
            &.card-type-002 {
              background-image: url('/media/images/payment/mastercard.png');
            }
            /* amex */
            &.card-type-003 {
              background-image: url('/media/images/payment/american_express.png');
            }
            /* maestro UK and international */
            &.card-type-024,
            &.card-type-042 {
              background-image: url('/media/images/payment/maestro.png');
            }
            /* visa electron */
            &.card-type-033 {
              background-image: url('/media/images/payment/visa_electron.png');
            }
            @media only screen and (-moz-min-device-pixel-ratio: 1.5),
              only screen and (-o-min-device-pixel-ratio: 3/2),
              only screen and (-webkit-min-device-pixel-ratio: 1.5),
              only screen and (min-devicepixel-ratio: 1.5),
              only screen and (min-resolution: 1.5dppx) {
              /* visa */
              &.card-type-001 {
                background-image: url('/media/images/payment/visa@2x.png');
              }
              /* mastercard */
              &.card-type-002 {
                background-image: url('/media/images/payment/mastercard@2x.png');
              }
              /* amex */
              &.card-type-003 {
                background-image: url('/media/images/payment/american_express@2x.png');
              }
              /* maestro UK and international */
              &.card-type-024,
              &.card-type-042 {
                background-image: url('/media/images/payment/maestro@2x.png');
              }
              /* visa electron */
              &.card-type-033 {
                background-image: url('/media/images/payment/visa_electron@2x.png');
              }
            }
            &.deselected-card-type {
              opacity: 0.25;
            }
            &.selected-card-type {
              border-color: $color-grey;
              opacity: 1;
            }
          }
        }
      }
      .card-type {
        @extend %hide;
      }
      .card-exp {
        width: 100%;
        @include breakpoint($medium-up) {
          float: right;
          padding-top: 33px;
          padding-bottom: 5%;
          width: 50%;
        }
        .checkout_spc_v2 & {
          float: left;
          margin-top: 0;
          padding: 0;
          width: 48%;
        }
        .card-expiration-month {
          width: 48%;
          float: left;
          .checkout_spc_v2 & {
            width: 100%;
            float: none;
          }
          select.expiration_month {
            @extend %show;
            width: 100%;
            .checkout_spc_v2 & {
              @extend %hide;
            }
          }
          label {
            @extend %hide;
          }
          .ux-expiry-date {
            @extend %hide;
            .checkout_spc_v2 & {
              @extend %show;
              width: 100%;
            }
          }
        }
        .section-separation {
          @extend %show;
          line-height: 43px;
          width: 4%;
          text-align: center;
          .checkout_spc_v2 & {
            @extend %hide;
          }
        }
        .card-expiration-year {
          float: right;
          width: 48%;
          .checkout_spc_v2 & {
            @extend %hide;
          }
          select.expiration_year {
            width: 100%;
          }
        }
      }
      .card-cvn {
        clear: both;
        width: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
        @include breakpoint($medium-up) {
          width: 49%;
          margin-top: 2%;
          margin-bottom: 2%;
        }
        .checkout_spc_v2 & {
          float: right;
          width: 48%;
          margin: 0;
          clear: none;
        }
        .cv_number_field {
          width: 100%;
        }
        .security-code {
          clear: both;
          @include breakpoint($medium-up) {
            position: absolute;
            left: 103%;
            top: 20%;
            width: 49%;
          }
        }
      }
    }
    #checkout_reviewGiftCardOnly {
      .submit {
        text-align: right;
      }
    }
    &.paypal {
      .submit input {
        width: 100%;
        @media (min-width: 1024px) {
          width: auto;
        }
      }
    }
  }
}

#review-panel {
  &.checkout-panel {
    .submit {
      .afterpay-button {
        min-width: 217px;
        text-align: #{$ldirection};
        background: url(/media/images/checkout/cp-logo-white-transparent.svg) #{$black} no-repeat 75%
          42%;
        padding-#{$ldirection}: 15%;
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 3%;
          background-position: 85% 42%;
          background-size: 90px 25px;
        }
        @include breakpoint($iphonex-landscape-only) {
          padding-#{$ldirection}: 30%;
          background-position: 61% 46%;
        }
        @include breakpoint($small-med-only) {
          padding-#{$ldirection}: 14%;
          background-position: 78% 46%;
        }
        @include breakpoint($medium-portrait-only) {
          padding-#{$ldirection}: 23%;
          background-position: 67% 46%;
        }
        &:hover {
          background-color: #{$color-green};
        }
      }
    }
  }
}

section.checkout-panel--recommended-products {
  .checkout-panel__header {
    .checkout-panel__heading {
      font-size: 25px;
      line-height: 1;
    }
  }
  .recommended-products-content {
    .recommended-products-content__items {
      display: flex;
      .recommended-items {
        margin: 0 1%;
        text-align: center;
        width: 33%;
        a {
          text-decoration: none;
        }
        .product-brief__image {
          background-color: $color-mpp-light-gray;
          margin-bottom: 20px;
        }
        .product-brief__unit-price {
          color: $color-medium-gray;
          font-style: italic;
        }
        .product-brief__actions {
          margin-top: 32px;
        }
      }
    }
  }
}

section.checkout-panel--shipping-edit-address {
  padding-left: 0;
  .select-address {
    border-bottom-width: 0;
  }
}

section.checkout-panel--gift-options-content {
  padding-left: 0;
}

section.checkout-panel--registration {
  &.finished,
  &.collapsed {
    @extend %show-block;
  }
  .checkout--confirmation-page & {
    padding: 1em 0;
    @include breakpoint($medium-up) {
      padding: 1em;
    }
    .checkout-panel__heading {
      font-size: 20px;
      text-transform: uppercase;
    }
    .registration-content__create-account {
      > section {
        margin-top: 20px;
      }
      .how--we-will-use-container {
        h3 {
          text-transform: uppercase;
        }
        ul li {
          margin-left: 20px;
        }
      }
      .checkout-registration {
        .form-item {
          margin-bottom: 1em;
        }
        .checkout-registration__password {
          input[type='password']:required.error.empty.error + label:before,
          input[type='password']:required.error.empty.touched + label:before,
          input[type='password']:required.error:empty.error + label:before,
          input[type='password']:required.error:empty.touched + label:before,
          input[type='password']:required.error:not(:checked).error + label:before,
          input[type='password']:required.error:not(:checked).touched + label:before,
          input[type='password']:required.error[value=''].error + label:before,
          input[type='password']:required.error[value=''].touched + label:before,
          input[type='password']:required:invalid.empty.error + label:before,
          input[type='password']:required:invalid.empty.touched + label:before,
          input[type='password']:required:invalid:empty.error + label:before,
          input[type='password']:required:invalid:empty.touched + label:before,
          input[type='password']:required:invalid:not(:checked).error + label:before,
          input[type='password']:required:invalid:not(:checked).touched + label:before,
          input[type='password']:required:invalid[value=''].error + label:before,
          input[type='password']:required:invalid[value=''].touched + label:before {
            color: $color-medium-gray;
          }
        }
      }
      @include breakpoint($medium-up) {
        .how--we-will-use-container,
        .checkout-registration {
          width: 49%;
        }
        .checkout-registration {
          float: left;
        }
        .how--we-will-use-container {
          float: right;
        }
        section.section-email-promotions {
          clear: both;
        }
      }
    }
  }
  label.error {
    color: $color-red;
  }
}

section.checkout-panel--samples {
  .samples-page & {
    .checkout-panel__header,
    .checkout-sample__header {
      @extend .checkout__header;
    }
    .checkout-panel__heading {
      font-size: 15px;
      line-height: 1.5;
      margin: 0;
    }
    .samples {
      .product-list-container {
        @include clearfix;
        .product {
          .product-img {
            @include breakpoint($medium-up) {
              min-height: 260px;
            }
            img {
              transform: scale(1.2);
            }
          }
          .skus {
            margin-left: 30%;
            @include breakpoint($medium-up) {
              margin-left: auto;
            }
            .form-item {
              @extend .samples-content__samples--button-container;
              .button {
                @extend .samples-content__samples--button;
              }
            }
          }
        }
      }
    }
    .continue-shopping__container--outer {
      &.top {
        @extend %hide;
        @extend %show_on_desktop-block;
      }
      .continue-shopping__container--inner {
        @include clearfix;
        @extend .samples-content__button--return;
        @extend %show-block;
        width: 100%;
        float: none;
        .add-button {
          @extend %show-block;
          width: 100%;
          @include breakpoint($medium-up) {
            float: right;
            width: auto;
          }
          input,
          button {
            width: 100%;
            @include breakpoint($medium-up) {
              width: auto;
            }
          }
        }
        a.no-samples {
          @extend %show-block;
          width: 100%;
          text-align: center;
          margin-top: 5%;
          @include breakpoint($medium-up) {
            float: left;
            width: auto;
            text-align: left;
            margin-top: 0;
          }
        }
        .checkout_spc_v2 & {
          @extend %hide;
        }
      }
    }
  }
}

section.checkout-panel--sign-in-paypal {
  &.finished,
  &.collapsed {
    @extend %hide;
  }
}

section.checkout-panel--confirmation {
  .checkout--confirmation-page & {
    margin-bottom: 1em;
    padding: 0;
    @include breakpoint($medium-up) {
      padding: 1em;
    }
  }
  .confirmation-content {
    margin-bottom: 1em;
    overflow: hidden;
    .confirmation-content__confirm-text--not-signed-in {
      .link {
        border: 0 none;
        color: $color-green;
        text-decoration: none;
      }
    }
  }
  header {
    h2 {
      font-size: 20px;
      text-transform: uppercase;
    }
  }
  #viewcart-panel {
    @extend %hide;
    .checkout_spc_v2 & {
      @extend %show;
      @extend %hide_on_desktop;
      width: 100%;
    }
  }
  .checkout-panel__heading {
    font-size: 20px;
    text-transform: uppercase;
    &.mobile_hidden,
    &.mobile-hidden {
      @extend %hide;
      @extend %show_on_desktop;
    }
    &.pc_hidden,
    &.pc-hidden {
      @extend %show;
      @extend %hide_on_desktop;
    }
  }
  .confirmation-content__email-address {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
    float: left;
    text-align: left;
    width: 100%;
    margin-bottom: 1em;
    .confirmation-content__wrong_email {
      @extend %hide;
      .checkout_spc_v2 & {
        @extend %show;
        float: right;
        a {
          text-transform: uppercase;
        }
      }
    }
  }
  #delivery-address-h {
    clear: both;
    display: none;
    + p {
      display: none;
    }
    .checkout_spc_v2 & {
      display: block;
      + p {
        display: block;
      }
    }
  }
  .confirmation-content__confirm-text {
    &--signed-in,
    &--not-signed-in {
      padding: 1em;
      background-color: $color-very-light-gray;
      font-weight: bold;
    }
  }
  .confirmation-content__actions {
    clear: both;
    .confirmation-content__print-buttons {
      float: none;
      margin: auto;
      text-align: center;
    }
  }
  .confirmation-content__info_link {
    @extend %hide;
    .checkout_spc_v2 & {
      @extend %show;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
    }
  }
  .confirmation-content__cart_products {
    .cart-items__item {
      padding: 1em 0;
      border-top: 1px solid $color-white-smoke;
      .cart-items__item--thumb {
        float: left;
        .cart--item__thumb-image {
          height: 110px;
          @include breakpoint($landscape-up) {
            height: auto;
          }
        }
      }
      .cart-items__item--desc {
        ~ div {
          display: inline-block;
        }
      }
    }
  }
  .confirmation-content__order-confirm-total {
    text-align: right;
    h3 {
      font-size: 20px;
      text-transform: uppercase;
      @extend %show;
      &.pc-hidden,
      &.pc_hidden {
        @extend %show;
        @extend %hide_on_desktop;
      }
      strong {
        margin-left: 20px;
      }
    }
  }
  .social-login-section {
    clear: both;
    .tooltip {
      text-align: center;
    }
  }
}

section.checkout-panel--sign-in-confirmation {
  .checkout--confirmation-page & {
    overflow: hidden;
    padding: 1em 0;
    @include breakpoint($medium-up) {
      padding: 1em;
    }
    .form-item {
      margin-bottom: 1em;
      &.password,
      &.submit {
        width: 100%;
      }
      &.password {
        .note {
          margin-top: 1em;
        }
      }
      &.email-address {
        .example-user-email,
        .email {
          display: block;
        }
      }
    }
    @include breakpoint($medium-up) {
      p {
        float: right;
        width: 49%;
      }
      #completed_transaction_signin {
        float: left;
        width: 49%;
      }
    }
  }
  .checkout-panel__heading {
    font-size: 20px;
    text-transform: uppercase;
  }
}
/* Panels */

.checkout-panel {
  .checkout-panel__header {
    .icon {
      &.icon--caret--up {
        @extend .icon--minus;
      }
      &.icon--caret--down {
        @extend %hide;
        @extend .icon--plus;
      }
    }
  }
  .cart-bundle {
    padding-top: 15px;
    @include breakpoint($medium-up) {
      width: 80%;
    }
    &__item {
      border-bottom: 1px solid #{$color-lightest-grey};
      padding-bottom: 20px;
      margin-bottom: 20px;
      &-link {
        text-decoration: none;
      }
    }
  }
  .overlay-wrapper {
    display: inline;
    .overlay-link-cms.loading {
      position: relative;
      background: url('/media/images/ajax-loading.gif') no-repeat right center;
      background-size: contain;
      padding-right: 9%;
      opacity: 1;
      &:before {
        margin-right: 0;
      }
    }
  }
}
/* Drupal CMS content popups */
#colorbox {
  &.checkout-content-popup {
    @include breakpoint($medium-up) {
      #cboxContent {
        #cboxLoadedContent {
          //important provided here to override Loyalty popup style changes.
          /* stylelint-disable-next-line declaration-no-important */
          height: 80vh !important;
        }
      }
    }
    .customer-service__collapsible-block {
      &:first-child {
        border: none;
      }
      .collapsible-block__header {
        @extend %show-block;
        .collapsible-block__icon {
          @extend %hide;
        }
      }
      .collapsible-block__content {
        height: auto;
      }
    }
  }
  .waitlist-form {
    margin: 0;
    padding: 0;
    &__email {
      @include breakpoint($medium-down) {
        width: 60%;
      }
    }
    &__submit {
      @include breakpoint($medium-down) {
        width: 38%;
      }
    }
  }
}
/* Drupal CMS content popups */

.adyen_enabled {
  .gift-options-display-content {
    width: 100%;
  }
  .checkout-gift-note {
    width: 100%;
    @include breakpoint($medium-up) {
      width: 49%;
      float: #{$ldirection};
    }
  }
  .checkout-gift-message {
    width: 100%;
    @include breakpoint($medium-up) {
      width: 49%;
      float: #{$rdirection};
    }
  }
  .checkout {
    &__panel {
      &--heading {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 10px;
        .edit {
          float: #{$rdirection};
          font-family: #{$font--neue-haas-unica-pro};
        }
      }
    }
  }
  .place-order {
    &__title {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #{$color-light-gray};
    }
  }
  .review-edit-content {
    .payment-method-subheader {
      @include swap_direction(margin, 0);
      text-transform: uppercase;
      font-size: 16px;
    }
  }
  .payment_options-terms {
    display: none;
    width: 100%;
    max-width: 550px;
    padding-#{$ldirection}: 16px;
    .payment-terms {
      font-size: 15px;
      text-transform: none;
      margin-top: 10px;
      clear: both;
      .overlay-wrapper {
        display: inline-block;
        .overlay-link-cms {
          font-weight: normal;
        }
      }
    }
    &.paypal-terms {
      .local-submits {
        .adyen-submit {
          .btn-primary {
            float: #{$ldirection};
          }
        }
      }
    }
  }
  .payment-option {
    @include swap_direction(padding, 21px 0);
    width: 100%;
    border-bottom: 1px solid #{$color-light-gray};
    &:first-child {
      margin-top: 20px;
      @include breakpoint($medium-up) {
        margin-top: 0;
      }
      padding-top: 21px;
      border-top: 1px solid #{$color-light-gray};
    }
    .field {
      &:checked {
        & + label {
          .payment_options-terms {
            display: block;
          }
        }
      }
    }
  }
  .all_card_img {
    height: 24px;
    margin-#{$ldirection}: 10px;
  }
  .paypal-logo {
    vertical-align: middle;
    margin-#{$ldirection}: 10px;
  }
  .clearpay-logo {
    @include swap_direction(margin, 0 10px);
  }
  .clearpay-button {
    max-width: 400px;
    .afterpay-button {
      width: 310px;
      border: none;
      background: #{$color-black};
      color: #{$color-white};
      @include breakpoint($medium-up) {
        width: 400px;
      }
      padding: 10px;
      .clearpay-adyen-logo {
        vertical-align: middle;
        padding-bottom: 3px;
      }
    }
  }
  .gift-card-entry-container {
    width: 346px;
    @include breakpoint($medium-up) {
      width: 400px;
    }
    padding-bottom: 20px;
    .gift-card-number {
      width: 210px;
      @include breakpoint($medium-up) {
        width: 230px;
      }
      float: #{$ldirection};
    }
    .gift-card-pin {
      width: 130px;
      float: #{$rdirection};
      input {
        width: 130px;
      }
    }
    .continue-btn {
      .form-submit {
        width: 100%;
      }
    }
  }
  .chckt-pm {
    border: none;
    &__header {
      padding-#{$ldirection}: 19px;
    }
    &__image {
      margin-#{$ldirection}: 0;
    }
    &__details {
      padding-#{$ldirection}: 5px;
      padding-bottom: 0;
    }
    .chckt-form {
      padding-#{$ldirection}: 20px;
    }
    .chckt-checkbox {
      position: static;
      float: #{$ldirection};
      margin-top: 5px;
    }
    .chckt-input-field {
      @include swap_direction(padding, 6px 10px 5px 10px);
      border-radius: 0;
      height: 44px;
      border-color: #{$color-light-gray};
      width: 100%;
      &--error {
        color: #{$color-red};
        border: 1px solid #{$color-red};
      }
      &--recurring {
        border: 1px solid #{$color-light-gray};
      }
      &--cvc {
        width: 100%;
        max-width: 174px;
      }
    }
    .chckt-form-label {
      &--exp-date {
        width: 55%;
        @include breakpoint($medium-up) {
          width: 46%;
        }
      }
      &--cvc {
        width: 36%;
        @include breakpoint($medium-up) {
          width: 46%;
        }
      }
      &--full-width {
        width: 100%;
      }
      &__error-text {
        color: #{$color-red};
        text-transform: none;
        font-weight: normal;
      }
      &__text {
        float: none;
      }
    }
  }
  .chckt-pm-list {
    .chckt-button-container {
      padding-#{$ldirection}: 22px;
      margin-bottom: 10px;
      .chckt-button {
        &--submit {
          @include swap_direction(padding, 14px);
          max-width: 360px;
          background-color: #{$color-black};
        }
        &--disabled {
          background-color: #{$color-gray};
        }
      }
      .chckt-more-pm-button {
        @include swap_direction(padding, 0);
        border: none;
        color: #{$color-black};
        &__icon {
          display: none;
        }
        &__text {
          text-decoration: underline;
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
  .local-submits {
    .pc_hidden {
      visibility: hidden;
    }
  }
  .after_pay-installment {
    display: block;
    padding-#{$ldirection}: 10px;
    @include breakpoint($medium-up) {
      display: contents;
    }
  }
  .paypal-terms {
    .local-submits {
      .pc_hidden {
        visibility: visible;
        @include breakpoint($medium-up) {
          visibility: hidden;
        }
      }
    }
  }
  .select-address {
    .select-menu {
      select {
        width: 100%;
      }
    }
  }
  .checkout-panel {
    &--sticky-footer {
      visibility: hidden;
    }
  }
}

.paypal-processing {
  @include swap_direction(padding, 10px);
  @include swap_direction(margin, 0 auto);
  #{$ldirection}: 0;
  position: fixed;
  #{$rdirection}: 0;
  text-align: center;
  top: 10vw;
  width: 100%;
  z-index: 999;
  opacity: 1;
  .process-content {
    @include swap_direction(padding, 30px 10px);
    @include swap_direction(margin, 0 auto);
    background: #{$color-white};
    max-width: 335px;
    z-index: 999;
    position: relative;
    opacity: 1;
  }
  .paypal-logo {
    padding-top: 20px;
    width: 90px;
  }
  .lamer-logo {
    padding-bottom: 25px;
    width: 135px;
  }
  .paypal-overlay {
    background: #{$color-black};
    opacity: 0.7;
    #{$ldirection}: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
}

.adyen-viewcart-buttons-panel {
  .checkout-buttons-content {
    &__container {
      .paypal-checkout {
        padding-#{$rdirection}: 0;
        min-width: 270px;
        margin-top: 5px;
        @include breakpoint($medium-up) {
          width: auto;
          margin-top: 0;
        }
        display: inline-block;
        vertical-align: middle;
        border: 0;
      }
    }
  }
}
