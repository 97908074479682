.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-submit {
        .pr-control-label {
          input[type='checkbox'] {
            left: 0;
            vertical-align: middle;
          }
        }
        .pr-checkbox.pr-has-error {
          .pr-control-label {
            span {
              color: $color-error;
            }
          }
        }
      }
    }
  }
}

.product-full__container {
  .product-full__offer__text--mobile {
    display: none;
  }
}
