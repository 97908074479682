/* Media query variable */
$iphonex-landscape-only: (
    device-width: 812px
  )
  and
  (
    device-height: 375px
  ); // iPhoneX landscape
/* Media query variable */

/* Mixins */
%hide {
  display: none;
}
%show {
  display: inline-block;
}
%show-block {
  display: block;
}
%show_on_desktop {
  @include breakpoint($medium-up) {
    display: inline-block;
  }
}
%show_on_desktop-block {
  @include breakpoint($medium-up) {
    display: block;
  }
}
%hide_on_desktop {
  @include breakpoint($medium-up) {
    display: none;
  }
}
/* Mixins */

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  margin-bottom: 10px;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: underline;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    color: $color-medium-gray;
    border: 1px solid $color-light-gray;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 2;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color-medium-gray transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: $color-medium-gray transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
  &.tooltip-btm-left-margin {
    margin: 0 0 14px 20px;
  }
  &.tooltip-right-margin {
    margin-right: 15px;
  }
  &.tooltip-top-margin {
    margin-top: 15px;
  }
  &.tooltip-bottom-margin {
    margin-bottom: 15px;
  }
  &.tooltip-margin {
    margin: 15px 0 15px 0;
  }
  &.text-right {
    text-align: right;
  }
  &.style-right {
    text-align: right;
    .tooltiptext-over {
      text-align: left;
      left: auto;
      right: 0;
      &:before {
        left: auto;
        right: 17%;
      }
    }
  }
  &.text-left {
    text-align: left;
  }
  .text-small {
    font-size: 10px;
  }
  .tooltip-bis::before {
    left: 50%;
  }
}

.tooltip--waitlist {
  margin-top: 10px;
}

.content-block-collapsible__header {
  .content-block-collapsible__headline {
    .heading {
      font-size: 30px;
    }
  }
}
/* Gift Card Landing page */
.section-gift-card {
  .customer-service {
    margin-top: 0;
    padding-top: 0;
  }
  .mpp-container {
    padding-bottom: 0;
  }
  .gc-terms {
    padding-top: 0;
  }
}
/* Gift Card Landing page */

.privacy-form-container {
  margin-top: 25px;
  label {
    font-size: 10px;
    cursor: default;
  }
}
/*
Overwriting the defaul base rule of:
.adpl label[data-required]:before { content: '' };
*/
.adpl {
  input[type='checkbox'] ~ .label,
  input[type='checkbox'] ~ label {
    @include icon('checkbox');
  }
  input[type='checkbox']:checked ~ .label,
  input[type='checkbox']:checked ~ label {
    @include icon('checkbox--checked');
  }
}

.product-quickshop,
.product-full {
  .product-sku-price {
    &__value,
    .product-sku-unit-price {
      float: left;
    }
  }
}

.invalid_marker,
.valid_marker {
  @extend %hide;
}

.site-header {
  .site-header__utility-logo {
    @include breakpoint($large-up) {
      width: 200px; /* Fixed iPad logo */
    }
  }
}

.site-footer {
  .site-email-signup {
    &__fields {
      .tooltip--email_signup {
        text-align: center;
        @include breakpoint($large-up) {
          text-align: left;
        }
      }
    }
  }
  .social-media-links {
    @include breakpoint($large-up) {
      margin: 0;
      position: absolute;
      bottom: 27px;
    }
  }
  &__bottom {
    @include breakpoint($large-up) {
      padding-bottom: 15px;
    }
  }
  &-pc__column {
    min-height: 210px;
  }
}

#qas {
  .error {
    @extend .messages;
  }
}

.checkedEmpty {
  @extend .error;
}
/* SPP R&R */
@media (max-width: 480px) {
  .product-full__price-wrapper {
    height: auto;
    .product-full__rating {
      float: left;
      clear: both;
      .p-w-r {
        margin: 0;
      }
    }
  }
}

.content-block-formatter__header {
  .content-block__typography {
    .content-block-formatter__cta {
      .button {
        @include breakpoint($medium-up) {
          margin-left: 0;
        }
      }
    }
  }
}

.password-field {
  position: relative;
  &__info {
    @include swap_direction(margin-top, 5px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    z-index: 99;
    &::before {
      @include breakpoint($medium-up) {
        content: '';
        position: absolute;
        top: 101%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: $color-black transparent transparent transparent;
        .gnav-util__content-inner & {
          top: 100%;
        }
      }
      .checkout-panel &,
      .password-reset & {
        border-color: transparent $color-black transparent transparent;
        top: 80%;
        left: 0;
      }
    }
    @include breakpoint($medium-up) {
      position: absolute;
      width: auto;
      left: 0%;
      bottom: 60px;
      .checkout-panel & {
        left: 100%;
        bottom: 0;
      }
      .checkout-registration__password & {
        left: 50%;
      }
      .password-reset & {
        left: 100%;
        bottom: 0;
      }
      .gnav-util__content-inner & {
        bottom: 73px;
      }
    }
  }
  &__rules {
    @include swap_direction(padding, 10px);
    column-count: 2;
    font-size: 0.8em;
    list-style: none;
    > li {
      @include swap_direction(padding, 3px 0);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      color: $color-black;
      transition: 0.2s;
      white-space: nowrap;
      &::before {
        @include swap_direction(margin, 0 6px 0 0);
        content: $cross-mark;
        display: inline-block;
        color: $color-red;
        font-size: 1em;
        line-height: 0;
        transition: 0.2s;
        text-shadow: 0 0 2px $color-black;
      }
      &.pass {
        color: $color-lighter-green;
        &::before {
          color: $color-lighter-green;
          content: $check-mark;
          text-shadow: 0 0 1px $color-black;
        }
      }
    }
    @include breakpoint($medium-up) {
      border: 1px solid $color-black;
      background-color: $color-white;
      .gnav-util__content-inner & {
        font-size: 0.9em;
      }
    }
    .checkout-panel &,
    .password-reset & {
      @include swap_direction(margin, 0);
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 10px);
        column-count: 1;
        top: 50px;
        position: relative;
      }
    }
  }
}

.password-request-page {
  @include swap_direction(margin, 0 auto);
  max-width: 650px;
}

.content-block--tout {
  @include breakpoint($medium-up) {
    .content-block-tout__text-headline {
      font-size: 33px;
    }
  }
}

// UK locale special requirement - hide not needed element
// in a base template file by overriding the display property here in the UK specific SCSS file
html:lang(en-GB) {
  #site-content {
    #email-signup__form > div.email-promo > label > span {
      &.label {
        &::before {
          display: none;
        }
      }
    }
  }
  // UK Specific Gift Icon requirement
  // overriding .pc-hidden class that's used for all locales
  // and reposition it to left
  .site-header__menu {
    .menu__item--category {
      &.holiday-launch {
        .menu__item--category-image {
          @include breakpoint($medium-up) {
            display: block;
            float: $ldirection;
            height: auto;
            max-width: 36px;
            padding: unset;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-up) {
  .product-full {
    .product-full__price-wrapper {
      height: auto;
    }
  }
}

//Clearpay Popup styles override
#afterpay-modal-overlay {
  #afterpay-modal-modal {
    width: 90%;
    height: 95%;
    @include breakpoint($medium-up) {
      width: 70%;
      height: 75%;
    }
  }
}

.spp__container {
  .product-full {
    &.product-full-v1 {
      .product-full__price-wrapper {
        .product-full__rating {
          position: absolute;
          #{$rdirection}: 19px;
          top: 41px;
          @include breakpoint($landscape-up) {
            position: unset;
          }
        }
      }
      .sticky-add-to-bag {
        .product-add-to-favorites div {
          text-transform: unset;
        }
      }
      .afterpay-text1 {
        font-size: 11px;
        line-height: 2;
        @include breakpoint($landscape-up) {
          font-size: 15px;
          line-height: 1.5;
        }
      }
      .afterpay-logo-link {
        .Clearpay-logo {
          width: 80px;
        }
      }
    }
  }
}

.checkout--confirmation-page {
  .social-login-section {
    .social-login__terms {
      display: block;
      text-align: right;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.sign-in-page {
  .return-user {
    .social-login-section {
      @media (min-width: 768px) {
        margin-top: 45px;
      }
    }
  }
}
