///
/// @file global/_variables.scss
///
/// \brief Variables
///
///UK specific SCSS variables
$color-autumn-gold: #6b2719;
$color-light-orange: #fef2f2;
$color-lighter-green: #07bb5b;
$check-mark: '\2713';
$cross-mark: '\2715';
