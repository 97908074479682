/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_notification div.bt-content {
  position: relative;
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  z-index: 1001;
  font-size: 12px;
  line-height: 16px;
  transition: all 5s;
  -webkit-transition: all 5s; /* Safari */
  bottom: -1000px;
}

#bt_notification div.bt-content a.bt-close-link {
  position: absolute;
  top: 10px;
  right: 10px;
}
/* CUSTOM POSITIONING */
#bt_notification div.bt-bottom-right {
  position: fixed;
  right: 10px;
  width: 305px;
  max-width: 100%;
  border-bottom-width: 0;
  div.bt-pp-section {
    margin-bottom: 20px;
  }
  .btn {
    @extend .button;
    margin-top: 10px;
    width: 100%;
  }
}

#bt_notification div.bt-bottom-full {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-bottom-width: 0;
  z-index: 9999;
  width: 100%;
}
/* BRAND SPECIFICS */
#bt_notification div.bt-content {
  border-color: $color-dark-gray;
  border-width: 10px;
  padding: 15px;
  color: #535353;
  @include breakpoint($med-small-down) {
    padding: 10px;
  }
}

#bt_notification div.bt-content a {
  color: $color-white;
}

#bt_notification div.bt-content a.bt-close-link {
  display: block;
  width: 15px;
  height: 15px;
  background: url('/media/images/cookies/close_x.png') 50% 50% no-repeat;
  text-indent: -9999em;
  @include breakpoint($med-small-down) {
    display: none;
  }
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_settings div.bt-content {
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  z-index: 1001;
  font-size: 12px;
}

#bt_settings div.bt-content div.bt-intro {
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy {
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy p {
  margin-top: 1em;
}
/* TOGGLE SLIDER */
#bt_settings div.bt-content div.slide,
#bt_settings div.bt-content ul.slide-toggle {
  position: relative;
  overflow: hidden;
  width: 525px;
  margin-bottom: 0px;
}

#bt_settings div.bt-content div.slide {
  margin: 20px 0;
}

#bt_settings div.bt-content ul.slide-toggle {
  background: transparent url('/media/images/cookies/all-off.png') 50% 100% no-repeat;
  background-size: contain;
  @include breakpoint($med-small-down) {
    background: transparent url('/media/images/cookies/m_all-off.png');
    width: 246px;
    padding-left: 0;
    background-size: 246px 147px;
  }
}

#bt_settings div.bt-content div.mask {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 0px;
  border-right: 1px solid $color-light-gray;
  transition: width 1s;
  -webkit-transition: width 1s; /* Safari */
}

#bt_settings div.bt-content div.mask ul.slide-toggle {
  background-image: url('/media/images/cookies/all-on.png');
  @include breakpoint($med-small-down) {
    background: transparent url('/media/images/cookies/m_all-on.png');
    width: 246px;
    padding-left: 0;
    background-size: 246px 147px;
  }
}

#bt_settings div.bt-content div.mask div.drag {
  position: absolute;
  bottom: 12px;
  right: 70px;
  width: 26px;
  height: 11px;
  background: transparent url('/media/images/cookies/icon_arrow.png') 50% 100% no-repeat;
  @include breakpoint($med-small-down) {
    right: 30px;
    bottom: 11px;
  }
}

#bt_settings div.bt-content ul.slide-toggle li {
  cursor: pointer;
  float: left;
  width: 175px;
  height: 149px;
  list-style-type: none;
  @include breakpoint($med-small-down) {
    width: 82px;
    height: 147px;
  }
}

#bt_settings div.bt-content ul.slide-toggle li.middle {
}

#bt_settings div.bt-content ul.slide-toggle li.selected {
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: block;
  font-weight: bold;
  text-align: center;
  margin: 5px;
}
/* PRIVACY INFORMATION */
#bt_settings div.bt-content .bt-privacy-info {
  display: none;
  overflow: hidden;
}

#bt_settings div.bt-content .bt-privacy-info.selected {
  display: block;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  padding: 15px 1em 15px 40px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will h3 {
  background-image: url('/media/images/cookies/icon_safe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot h3 {
  background-image: url('/media/images/cookies/icon_unsafe_lock.png');
  width: 482px;
}

#bt_settings div.bt-content .bt-privacy-info div {
  width: 48%;
  margin-right: 2%;
  float: left;
  @include breakpoint($med-small-down) {
    width: 100%;
    float: none;
  }
}

#bt_settings div.bt-content .bt-privacy-info div ul {
  list-style-type: none;
  padding-left: 0;
  margin: 1em;
}

#bt_settings div.bt-content .bt-privacy-info div ul li {
  margin: 0.8em 0;
  padding-left: 30px;
  background-position: 0 50%;
  background-repeat: no-repeat;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will ul li {
  background-image: url('/media/images/cookies/icon_safe_thick.png');
  list-style-type: none;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot ul li {
  background-image: url('/media/images/cookies/icon_unsafe_thick.png');
  list-style-type: none;
}
/* BRAND SPECIFICS */
#bt_settings div.bt-content {
  border-color: $color-light-gray;
  border-width: 2px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

#bt_settings div.bt-content-desktop {
  padding: 2em;
  height: 100%;
}

#bt_settings div.bt-content div.bt-intro h2 {
  text-transform: none;
  color: $color-light-gray;
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: none;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  background-color: #f9f9ef;
}

.tealium {
  display: none !important;
}
