.address-overlay input[type='tel'],
.address-overlay input[type='text'],
.address-overlay input[type='email'],
.address-overlay input[type='password'] {
  width: 100%;
  @include breakpoint($small-down) {
    padding: 0 10px;
    width: 93%;
  }
}

.account-section {
  .account-page__section--subheading {
    font-size: 20px;
    text-transform: uppercase;
  }
}

.address-book-page__overlay {
  @include breakpoint($small-down) {
    h2 {
      margin-top: 0;
    }
  }
  .address-form {
    .form-item {
      @include breakpoint($small-down) {
        select {
          padding: 0 10px;
          width: 93%;
        }
      }
      &.country_container {
        select {
          width: 100%;
        }
      }
      &.title label,
      .valid_marker {
        display: none;
      }
      width: 100%;
      @include breakpoint($landscape-up) {
        float: left;
        width: 50%;
        padding-right: 1.33em;
        padding-bottom: 0;
        &.postal-code,
        &.state,
        &.city {
          width: 33%;
          float: left;
        }
        &.state {
          label {
            display: block;
          }
        }
      }
    }
  }
}
