.social-login {
  position: relative;
  .facebook-button {
    color: $color-white;
    background-color: $color-fb-blue;
    border: none;
    height: 40px;
    width: 100%;
    letter-spacing: 0.25px;
    border-radius: 4px;
    top: 0;
    margin: 0;
    &.facebook-button-disabled {
      position: relative;
      background-color: $color-medium-gray;
      pointer-events: none;
      z-index: 1;
      & + .social-login__container {
        z-index: -1;
      }
    }
  }
  .social-login__terms {
    input[type='checkbox'] ~ label::before {
      outline: none;
    }
    input[type='checkbox'] ~ label > p {
      display: inline;
    }
  }
}
