.orders-page {
  .account-orders-list {
    .orders-list__container {
      .cart-item__wrap,
      .cart-item {
        .cart-item__price,
        .cart-item__qty {
          width: 25%;
        }
        .cart-item__price {
          text-align: left;
        }
      }
    }
  }
}
