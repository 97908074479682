//Sign In Page
.sign-in-page {
  @include breakpoint($landscape-up) {
    .new-account__container {
      .social-login__divider {
        &:before {
          height: calc(100% - 12%);
        }
      }
      .tooltiptext-over {
        font-size: get-rem(12px);
      }
    }
  }
  /*
    This rule has been added due to NA dropping support for adaptive Placeholkders which the UK can support,
    If base is updated to support ADPL then this should be removed
  */
  .return-user,
  .new-account {
    .form-item {
      label,
      fieldset {
        text-align: left;
      }
      &.first-name,
      &.last-name,
      &.password,
      &.email-address {
        label {
          display: block;
        }
      }
      &.password {
        float: none;
      }
    }
  }
  /*
    This rule has been added due to NA dropping support for adaptive Placeholkders which the UK can support,
    If base is updated to support ADPL then this should be removed
  */
}
