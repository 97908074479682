@include breakpoint($large-up) {
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    .site-header.sticky .site-header .menu__content--lvl-1 {
      top: 35px;
    }
  }
}

body {
  &.elc-user-state-logged-in {
    .site-header.sticky .site-header {
      &__menu {
        @include breakpoint($medium-landscape-only) {
          li.menu__item--category:nth-last-child(n + 8),
          li.menu__item--category:nth-last-child(n + 8) ~ li.menu__item--category {
            font-size: 0.82em;
            margin: 0 0.2em 0;
          }
        }
      }
      &__utility {
        @include breakpoint($medium-landscape-only) {
          .site-header__utility-right {
            .site-header__utility-item {
              background: $color-white;
            }
          }
        }
      }
    }
  }
}

.active-panel-samples,
.active-panel-signin {
  .site-header__utility-left {
    .mobile-checkout-back {
      margin-left: 15px;
      display: block;
      top: 12px;
      position: relative;
      cursor: pointer;
      .icon {
        height: 27px;
        width: 16.8px;
      }
    }
  }
}

.site-header__menu__mobile-util-links {
  width: 96%;
  &--tier-level {
    width: 56%;
    display: inline-block;
    padding: 0 5px;
  }
}
