.welcome15-overlay {
  #cboxLoadedContent {
    @include swap_direction(padding, 0);
    background-color: $color-dark-blue;
    .welcome-offer-popup-wrapper {
      text-align: center;
      background-image: url(/media/export/cms/loyalty/welcome_popup_banner_mobile.png);
      background-size: 100% 100%;
      @include breakpoint($landscape-up) {
        background-image: url(/media/export/cms/loyalty/welcome_popup_banner_pc.png);
        background-repeat: no-repeat;
      }
      &__header {
        visibility: hidden;
        height: 300px;
        @include breakpoint($landscape-up) {
          height: 240px;
        }
      }
      &__subheader {
        @include swap_direction(padding, 0 20px);
        font-size: 24px;
        color: $color-white;
        font-family: $font--subheading;
        font-style: italic;
        line-height: 1.25;
        @include breakpoint($landscape-up) {
          @include swap_direction(padding, 0 10%);
        }
      }
      &__button {
        @include swap_direction(margin, 20px 0 10px 0);
        padding-#{$ldirection}: 50px;
        padding-#{$rdirection}: 50px;
        a {
          text-decoration: none;
          font-size: 11px;
        }
        &:hover {
          color: $color-white;
          a {
            color: $color-white;
          }
        }
      }
      &__footer {
        color: $color-white;
        padding-bottom: 0;
        margin-bottom: 10px;
        font-family: $font--text;
        font-size: 13px;
        a {
          color: $color-white;
          font-size: 13px;
          font-family: $font--text;
          text-transform: uppercase;
          @include breakpoint($landscape-up) {
            text-transform: initial;
          }
        }
      }
    }
  }
}

@media (min-width: $medium-up) {
  .site-footer {
    .site-footer-pc__column {
      .site-footer__country-chooser-wrapper {
        margin-top: 10px;
        position: unset;
      }
    }
  }
}

.selectBox-dropdown-menu {
  width: 170px !important;
}

@include breakpoint($medium-portrait-only) {
  .loyalty__market__status__header__tier- {
    &1,
    &2,
    &3 {
      .loyalty__market__status__header__title {
        font-size: 18px;
      }
    }
  }
  .loyalty__market__status__content .loyalty__market__status__section {
    padding: 5px;
  }
  section.checkout-panel--shipping fieldset {
    .form-item {
      width: 100%;
    }
  }
  .checkout.index {
    .column.left {
      section.checkout-panel--review {
        #checkout_payment {
          .card-exp {
            float: none;
          }
        }
      }
    }
  }
}

#cboxContent {
  height: 100% !important;
  #cboxLoadedContent {
    height: 80vh !important;
    @include breakpoint($medium-up) {
      height: 100% !important;
    }
    > div {
      // incoming element from perlgem js snippet that is loading the iframe content.
      height: 100%;
    }
  }
}

.loyalty__panel__offers__list-container {
  .loyalty__panel__offers__promo_desc {
    min-height: 88px;
  }
}

.checkout {
  &-panel--donations {
    display: none;
  }
}
// ipad fixes:
.loyalty__panel__offers__sku__product-image-container {
  img {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      height: inherit;
    }
  }
}

.loyalty__panel__offers__sku__list-container {
  .slides li {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 32%;
      text-align: center;
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 20px;
    }
  }
}

#non__loyalty__panel {
  @include breakpoint($medium-up) {
    text-align: center;
  }
}
