//colors
$color-suvagray: #888888;
$color-silver: #aaa;
$color-mineshaft: #333;
$color-gold: #fed202;
$color-lemon: #f6e914;
$color-celeste: #ccc;
$color-pumice: #c7c7c7;
$color-mountain-mist: #999;
$color-vista-white: #fbf8f6;

//fonts
$font-icons: icons;
$font-mono-tstar: tstar_mono_roundregular;
$font-helvetica: Helvetica;

//urls
$url-store-collection: url(/media/export/images/click_collect/lm_on.png);
$url-local-collection: url(/media/export/images/click_collect/ups-on.png);

@charset "UTF-8";

.click_and_collect_map {
  width: 100%;
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  .overlay-content {
    height: 100%;
    width: 100%;
    position: relative;
    .map-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      background: $color-white;
      .address-map-location-panel {
        bottom: 0;
        display: block;
        position: absolute;
        width: 22em;
        z-index: 2;
        background-color: $color-white;
        .locations {
          height: 100%;
          overflow-y: scroll;
          background-color: $color-white;
          cursor: pointer;
          padding-top: 10px;
          .messages {
            background-color: $color-white;
            border: 0;
            margin-top: 30px;
          }
        }
        header {
          position: absolute;
          width: 95%;
          z-index: 2;
          background: $color-white;
          height: 4em;
          padding: 10px;
          h2 {
            color: $color-black;
            font-size: 2em;
            padding-left: 0.5em;
          }
        }
        .location {
          overflow: hidden;
          padding: 1.5em 0.5em 1em;
          margin: 0;
          border-bottom: 1px solid $color-celeste;
          color: $color-black;
          transition: padding 0.5s, background 0.5s;
          .detail_reveal {
            display: none;
            &.selected {
              display: block;
            }
          }
          &:hover {
            padding: 2em 0.5em;
            background-color: $color-pumice;
          }
          &.selected {
            padding: 2em 0.5em;
            background-color: $color-pumice;
          }
          &:first-child {
            padding-top: 60px;
          }
          p {
            margin: 0 0 0.5em;
          }
          .image {
            float: left;
            width: 100px;
            margin: 0 1em 0 0;
            display: none;
            img {
              width: 100%;
            }
          }
          .distance {
            float: right;
            color: $color-mountain-mist;
            .uom {
              text-transform: lowercase;
            }
          }
          .location_description {
            font-style: italic;
          }
          .title {
            font-weight: bold;
            h2 {
              font-weight: bold;
              font-size: 14px;
              border: 0;
              padding: 0.5em 0;
              color: $color-black;
            }
          }
          div.button {
            clear: both;
            margin-top: 1em;
            padding: 9px 2px 8px;
            width: 100%;
            border: none;
          }
        }
        &.active {
          left: 0;
          top: 0;
          a.close span:before {
            content: '';
          }
        }
        .map .location {
          min-width: 200px;
        }
        a.close {
          display: block;
          position: absolute;
          right: 6px;
          top: 16px;
          text-decoration: none;
          z-index: 3;
          margin: 0;
          background: none;
          width: 13px;
          height: auto;
          text-indent: 0;
          cursor: pointer;
          span:before {
            display: block;
            font-family: $font-icons;
            speak: none;
            content: '';
            font-size: 16px;
            color: $color-black;
            text-decoration: none;
          }
        }
      }
      .address-map-search-panel {
        font-size: 1.4em;
        position: absolute;
        z-index: 1;
        border-top: 0;
        left: 25em;
        padding: 1em 0.5em 1em;
        background: $color-white;
        width: 28em;
        * {
          box-sizing: border-box;
        }
        .search-box {
          overflow: hidden;
          width: 27em;
          label {
            display: block;
            font-size: inherit;
            margin: 0 0 1em;
            font-weight: bold;
          }
        }
        .search-box-field {
          width: 65%;
          font-size: inherit;
          margin: 0 2%;
          height: 42px;
          padding: 0;
          float: left;
          text-align: center;
          border-radius: 0;
        }
        .search-box-submit {
          @include button();
          min-width: 0;
        }
        .search-box-cancel {
          width: 32%;
          font-size: inherit;
          margin-right: 2px;
          float: left;
          clear: none;
          i:before {
            padding-right: 0;
          }
        }
        .status-bar-wrapper {
          width: 80%;
          margin-left: 10%;
          position: fixed;
          bottom: 20px;
          left: 50%;
          width: 30em;
          margin-left: -15em;
          .status-bar .message {
            padding: 0.5em;
            border: 1px solid $color-silver;
            box-shadow: 0 1px 4px $color-mineshaft;
            border-top: 0;
            &:first-child {
              border-radius: 5px 5px 0 0;
              border-radius: 0;
            }
            &:last-child {
              border-radius: 0 0 5px 5px;
            }
            .close {
              float: right;
              a {
                text-decoration: none;
                color: inherit;
              }
            }
            &.error {
              background: $color-gold;
              color: $color-white;
            }
            &.warning {
              background: $color-lemon;
              color: $color-black;
            }
            &.success {
              background: $color-white;
              border-color: $color-black;
              color: $color-black;
            }
            &.information {
              background: $color-black;
              color: $color-white;
            }
          }
        }
        .search-box-submit .loading span:before {
          content: '';
        }
      }
      .search-box-geolocate {
        font-size: inherit;
        margin-right: 2px;
        float: left;
        clear: none;
        display: block;
        position: relative;
        width: 1.7em;
        height: 1.7em;
        font-size: 32.9px;
        right: 0.7em;
        z-index: 1;
        overflow: hidden;
        border-radius: 50%;
        color: $color-white;
        text-align: center;
        text-overflow: ellipsis;
        background: $color-black;
        cursor: pointer;
        margin-top: 15px;
        font-family: $font-icons;
        padding-top: 5px;
        .loading i:before {
          content: '';
        }
      }
      .gm-style {
        font-family: $font-mono-tstar, $font-helvetica;
      }
      .address-map-directions-panel {
        bottom: 0;
        display: block;
        position: absolute;
        width: 23em;
        z-index: 2;
        background-color: $color-white;
        left: 0;
        top: 0;
        bottom: 0;
        display: block;
        position: absolute;
        width: 22em;
        z-index: 2;
        background-color: $color-white;
        .locations {
          height: 100%;
          overflow-y: scroll;
          background-color: $color-white;
          cursor: pointer;
          padding-top: 10px;
        }
        .location {
          overflow: hidden;
          padding: 0.5em 0.5em 1em;
          margin: 0;
          border-bottom: 1px solid $color-celeste;
          color: $color-black;
          transition: padding 0.5s, background 0.5s;
          p {
            margin: 0 0 0.5em;
          }
          .image {
            float: left;
            width: 100px;
            margin: 0 1em 0 0;
            display: none;
            img {
              width: 100%;
            }
          }
          .distance {
            float: right;
            color: $color-mountain-mist;
            .uom {
              text-transform: lowercase;
            }
          }
          .location_description {
            font-style: italic;
          }
          .title h2 {
            font-weight: bold;
            font-size: 14px;
            border: 0;
            padding: 0.5em 0;
            color: $color-black;
          }
          div.button {
            clear: both;
            margin-top: 1em;
            padding: 9px 2px 8px;
            width: 100%;
            border: none;
          }
        }
        header {
          height: 45px;
          padding-top: 5px;
          .button {
            border: none;
          }
          h2 {
            color: $color-black;
            font-size: 2em;
            padding-left: 0.5em;
          }
          div a.close span:before {
            display: block;
            speak: none;
            content: '';
            font-size: 16px;
            color: $color-black;
            text-decoration: none;
          }
        }
        .directions .button {
          border: none;
          margin-left: 5%;
          a.btn {
            padding: 9px 19px 8px;
          }
        }
        a.close {
          display: block;
          position: absolute;
          z-index: 3;
          background: $color-vista-white;
          left: 85%;
          padding: 12px;
          border: none;
          font-size: 4em;
          color: $color-black;
          min-width: 0;
          cursor: pointer;
        }
      }
      .map-info-window {
        background: $color-black;
        color: $color-white;
        padding: 10px;
        .location {
          width: 268px;
          padding-left: 17px;
          .title {
            padding-bottom: 5px;
          }
          .button {
            padding: 4px 0 5px;
            a.btn {
              padding: 5px;
              text-decoration: none;
            }
          }
          .image {
            display: none;
          }
        }
        .title span {
          color: $color-white;
          margin: 0;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .directions {
        background: $color-white;
        color: $color-black;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 93%;
      }
      .search-box-toggle {
        width: 4em;
        height: 4em;
        border-radius: 50%;
        position: relative;
        background-color: $color-white;
        color: $color-black;
        padding: 6px;
        font-size: 14px;
        border: solid 1px;
        cursor: pointer;
        right: 1.7em;
        z-index: 1;
      }
      .search-box-toggle-tooltip {
        position: absolute;
        width: 23.9em;
        height: 5.9em;
        color: $color-white;
        background-color: $color-black;
        font-size: 15px;
        bottom: 3.7em;
        right: 1em;
        z-index: 1;
        padding: 11px;
        padding-right: 60px;
      }
      .collection-tooltip {
        display: none;
      }
      .store-tooltip {
        display: none;
      }
      .search-box-cancel {
        background-color: $color-black;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 0.62em;
        right: 0.7em;
        padding-top: 0.1em;
        margin: 0;
        z-index: 1;
        width: 2em;
        height: 2em;
        color: $color-white;
        font-size: 20px;
        font-family: $font-icons;
        .loading i:before {
          content: '';
        }
      }
      .map {
        height: 100%;
        width: 100%;
        top: 0;
      }
      .map-side-tools {
        bottom: 15%;
        right: 0.4em;
        position: absolute;
      }
      .location .button {
        margin: 0;
        a {
          background-color: inherit;
          width: 45%;
          height: 3em;
          line-height: 1em;
          color: inherit;
          font-size: 11px;
          text-decoration: underline;
          padding-top: 10px;
          margin: -1px 0 0;
          min-width: 0;
        }
      }
    }
  }
  .close-container {
    display: none;
  }
}

.store-collection-button,
.local-collection-button {
  width: 206px;
  cursor: pointer;
  @include breakpoint($small-down) {
    width: 15%;
  }
}

@media (max-width: 480px) {
  .click_and_collect_map .overlay-content .map-container {
    .address-map-search-panel {
      left: 0;
      padding: 0;
      width: 100%;
      .search-box-field {
        width: 38%;
        height: 40px;
        width: 38%;
        border: 0;
        font-size: 15px;
        height: 40px;
      }
      .search-box-wrapper {
        width: 88%;
        background-color: $color-white;
        border-bottom: 1px $color-black solid;
      }
    }
    .address-map-directions-panel {
      width: 100%;
      top: 0;
      max-height: 100%;
      overflow-y: scroll;
    }
    .address-map-location-panel {
      width: 100%;
      &.hover {
        left: 0;
      }
      a.close {
        top: 0;
        right: 25px;
        border: 0;
        font-size: 2.5em;
      }
    }
    .map-info-window .location {
      width: auto;
    }
  }
}

@media (max-width: 1023px) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-field {
    width: 50%;
    height: 40px;
  }
}

@media (max-width: 767px) {
  #checkout_shipmethod {
    padding: 0;
  }
  .click_and_collect_map .overlay-content .map-container {
    .address-map-search-panel {
      .search-box-field {
        width: 38%;
      }
      .status-bar-wrapper {
        position: fixed;
        bottom: 50px;
        width: 100%;
        margin: 0;
        left: 0;
        text-align: center;
      }
    }
    .map-info-window .location {
      width: auto;
      padding: 0;
      .title {
        padding-top: 2px;
      }
      .location_description {
        display: none;
      }
      .opening_hours {
        display: none;
      }
      .image {
        display: none;
      }
      .location_more_info {
        color: $color-white !important;
        text-decoration: underline;
        cursor: pointer;
        line-height: 2em !important;
        display: block;
      }
      .distance p {
        line-height: 1.4em;
      }
      .address p {
        line-height: 1.4em;
      }
    }
    .location .title span {
      line-height: 2em;
    }
  }
  #shipmethod-panel .ship-method-group-label {
    margin-left: 0;
    label {
      line-height: 1.5em;
    }
  }
  body#viewcart .viewcart-buttons-panel .continue-buttons a.continue-checkout {
    margin: 10px 0;
  }
  .adp-placemark td {
    padding-left: 22px;
  }
}

@media (max-width: 850px) {
  .click_and_collect_map .overlay-content .map-container {
    .address-map-search-panel {
      left: 0;
    }
    .search-box-geolocate {
      width: 1.2em;
      height: 1.2em;
      right: 0;
      margin-top: 0;
      &.top span {
        display: block;
        font-size: 0.6em;
        margin: 1px 0 0 3px;
      }
    }
    .search-box-cancel {
      right: 0;
      font-size: 21px;
      top: 0;
      height: 2em;
      width: 2em;
      padding-top: 0.3em;
      background-color: $color-white;
      color: $color-black;
      font-weight: bolder;
      span:before {
        padding-top: 0;
      }
    }
    .map-info-window {
      max-width: 205px;
      .location {
        width: 190px;
        padding-left: 0;
      }
      .btn {
        padding-top: 4px;
        width: 48%;
      }
    }
    .address-map-directions-panel {
      top: 0;
      max-height: 100%;
      overflow-y: scroll;
      width: 100%;
      padding-right: 0;
      header {
        width: 100%;
      }
      a.close {
        right: 35px;
      }
    }
    .address-map-location-panel {
      top: auto;
      left: 0;
      bottom: 0;
      height: 40px;
      margin: 0;
      padding-bottom: 0;
      overflow: hidden;
      width: 100%;
      padding-right: 0;
      header {
        padding-top: 10px;
        width: 100%;
        h2 {
          margin-top: -5px;
        }
      }
      &.active {
        top: 0;
        height: auto;
        z-index: 3;
        a.close span:before {
          content: '\E014';
        }
      }
      .location {
        &:first-child {
          padding-top: 50px;
        }
        .distance {
          display: none;
        }
        .title .distance {
          display: block;
          .uom {
            color: $color-suvagray;
          }
        }
      }
      a.close {
        right: 35px;
        span:before {
          font-family: $font-icons;
          content: '\E014';
          color: $color-black;
        }
      }
    }
    .map-side-tools .search-box-geolocate {
      width: 1.7em;
      height: 1.7em;
      right: 0.7em;
      margin-top: 10px;
    }
  }
}

@media screen and(max-width: 1024px) and(min-width: 768px) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    left: 18em;
  }
}

.adp-placemark {
  background-color: $color-white;
  td {
    padding: 3px;
    color: $color-black;
  }
}

.adp-substep {
  color: $color-black;
  max-width: 200px;
}

.adp-summary {
  color: $color-black;
  max-width: 200px;
}

.adp-text {
  color: $color-black;
  max-width: 200px;
  width: 90% !important;
}

@media only screen and(min-device-width: 768px) and(max-device-width: 1024px) and(orientation: landscape) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    left: 19em;
  }
}

.map-container .adp-step {
  width: 90% !important;
}

.local-collection .overlay-content .map-container .search-box-toggle .toggle-img {
  background: $url-store-collection no-repeat;
  height: 3em;
  width: 3.2em;
  background-size: 79%;
  background-position-x: center;
}

.store-collection .overlay-content .map-container .search-box-toggle .toggle-img {
  background: $url-local-collection no-repeat;
  height: 3em;
  width: 3em;
  background-size: 83%;
  background-position-x: center;
}

html.mac .click_and_collect_map .overlay-content .map-container {
  .address-map-directions-panel header {
    height: 40px !important;
  }
  .address-map-location-panel header {
    height: 40px !important;
  }
}
