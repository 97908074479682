/* ApplePay integration */
.checkout-panel--viewcart-buttons {
  .bottom-viewcart-buttons {
    .applepay {
      .applepay-enabled {
        span.or {
          @extend %hide;
          .checkout_spc_v2 & {
            @extend %show;
          }
        }
        span.applepay-or {
          display: block;
          .checkout_spc_v2 & {
            @extend %hide;
          }
        }
        .applepay-error-container {
          color: $color-error;
          float: left;
          text-align: left;
        }
        .applepay-integration-wrapper {
          background: $gray;
          clear: both;
          float: right;
          margin-top: 5%;
          padding: 1em;
          width: 100%;
          @include breakpoint($landscape-up) {
            margin-top: 0;
          }
          .checkout_spc_v2 & {
            order: 2;
            -webkit-order: 2; /* Safari 6.1+ */
            display: -webkit-flex; /* Safari */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include breakpoint($landscape-up) {
              flex-direction: row-reverse;
            }
          }
          /*  User logged in */
          .elc-user-state-logged-in & {
            background: transparent;
            border: 0 none;
            padding: 0;
            @include breakpoint($landscape-up) {
              width: auto;
            }
          }
          &.applepay-error {
            background: $color-light-sand;
            border: 1px solid $color-error;
          }
          .applepay-button-container {
            text-align: center;
            @supports (-webkit-appearance: -apple-pay-button) {
              .apple-pay-button {
                display: block;
                overflow: hidden;
                text-indent: 100%;
                line-height: 2.5em;
                white-space: nowrap;
                -webkit-appearance: -apple-pay-button;
                -apple-pay-button-style: white-outline;
              }
              .apple-pay-button.button-style-buy {
                -apple-pay-button-type: buy;
              }
              .apple-pay-button.button-style-set-up {
                -apple-pay-button-type: set-up;
              }
            }
            @supports not (-webkit-appearance: -apple-pay-button) {
              .apple-pay-button {
                display: inline-block;
                background-size: 100% 60%;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                border-radius: 5px;
                padding: 0px;
                box-sizing: border-box;
                min-width: 200px;
                min-height: 32px;
                max-height: 64px;
              }
              .apple-pay-button-black {
                background-image: -webkit-named-image(apple-pay-logo-white);
                background-color: black;
              }
              .apple-pay-button-white {
                background-image: -webkit-named-image(apple-pay-logo-black);
                background-color: white;
              }
              .apple-pay-button-white-with-line {
                background-image: -webkit-named-image(apple-pay-logo-black);
                background-color: white;
                border: 0.5px solid black;
              }
            }
          }
          span.applepay-button-container {
            width: 100%;
            .checkout_spc_v2 & {
              order: 1;
              -webkit-order: 1; /* Safari 6.1+ */
            }
          }
          div.applepay-button-container {
            text-align: justify;
            .checkout_spc_v2 & {
              order: 2;
              -webkit-order: 2; /* Safari 6.1+ */
            }
            .terms_input {
              margin-top: 20px;
              padding-right: 30px;
              position: relative;
              @include breakpoint($landscape-up) {
                margin-top: 0;
              }
              &:before {
                content: '\e00b';
                position: absolute;
                right: 0;
                top: 0;
                @include breakpoint($landscape-up) {
                  position: static;
                }
              }
            }
            input[type='checkbox']:checked ~ .label:before,
            input[type='checkbox']:checked ~ label:before {
              content: '\e00a';
            }
          }
        }
      }
    }
  }
}
/* End of ApplePay integration */
