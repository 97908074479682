section.payment-info {
  .error_messages {
    color: $color-error;
  }
}

.account-overview__profile,
.account-setting__profile {
  @include breakpoint($medium-up) {
    margin-left: 0px;
  }
}

.account-profile__connected-accounts {
  p {
    text-transform: lowercase;
  }
}

.account-settings__container {
  .account-profile__connected-accounts {
    text-transform: unset;
  }
}

.myaccount-adyen-add-payment {
  @include swap_direction(padding, 20px);
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 0);
  }
  .account-section {
    &__nav {
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 0;
      }
    }
  }
  .payment_address {
    border: none;
    width: 100%;
    @include breakpoint($medium-up) {
      width: 50%;
    }
    .adyen-fields {
      .adyen-checkout {
        &__input {
          height: 45px;
        }
      }
    }
    .payment-form {
      &__adyen {
        &--option {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }
  .non-adyen-fields {
    .payment-address {
      @include breakpoint($medium-up) {
        width: 50%;
      }
      .selectbox {
        @include swap_direction(margin, 10px);
        width: 100%;
      }
    }
  }
}
