// Move checkbox and radio between mobile and PC
input[type='checkbox'],
input[type='checkbox'] ~ label::before,
input[type='checkbox'] ~ .label::before,
input[type='radio'],
input[type='radio'] ~ label::before,
input[type='radio'] ~ .label::before {
  background: $color-white;
  border-radius: 50%;
  float: none;
  margin-right: 7px;
  margin-left: 0;
  margin-top: 0;
}

input[type='checkbox'] ~ label,
input[type='checkbox'] ~ .label,
input[type='radio'] ~ label,
input[type='radio'] ~ .label {
  display: inline-block;
}

input[type='text'].search-form__field {
  &::-ms-clear {
    display: none;
  }
}

input,
select {
  &:required:invalid:not(:checked).checkedEmpty {
    border: 1px solid $color-error;
  }
}

input[type='checkbox']:required:not(:checked) {
  &.checkedEmpty ~ label {
    color: $color-error;
  }
}
